import { css } from '@emotion/core'
import React from 'react'
import { EmotionStyles } from '~/types/types'
import { BLACK, MAIN_FONT_FAMILY, WHITE } from '../../../constants/theme.styles'

const iconStyles = css`
  display: none;
  fill: none;
  stroke: ${WHITE};
  stroke-width: 4px;
  max-width: 18px;
  max-height: 18px;
  padding: 0 0 2px;
`
const iconContainerStyles = css`
  display: inline-block;
  overflow: hidden;
  min-width: 18px;
  max-width: 18px;
  min-height: 18px;
  max-height: 18px;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: #b6bfc2;
  transition: all 150ms;
  background: ${WHITE};
`

const StyledCheckboxIcon = () => {
  return (
    <div className="uiStyledCheckboxIcon" css={iconContainerStyles}>
      <svg viewBox="0 0 24 24" css={iconStyles}>
        <polyline points="20 6 9 17 4 12" />
      </svg>
    </div>
  )
}

const hiddenCheckboxStyles = css`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  vertical-align: baseline;
  :checked + .uiStyledCheckboxIcon {
    border-color: ${BLACK};
    background: ${BLACK};
    svg {
      display: block;
    }
  }
`

const baseLabelStyles = css`
  color: ${BLACK};
  font-family: ${MAIN_FONT_FAMILY};
  font-size: 16px;
  line-height: 1em;
  font-weight: 400;
`
const labelLeftStyles = css`
  padding-right: 12px;
`
const labelRightStyles = css`
  padding-left: 12px;
`

const baseContainerStyles = css`
  width: 100%;
  display: inline-flex;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
  align-items: center;
`
const fluidContainerStyles = css`
  display: flex;
  justify-content: space-between;
`
interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean
  label?: React.ReactNode | string
  labelPosition?: 'left' | 'right'
  containerStyles?: EmotionStyles
  labelStyles?: EmotionStyles
  fluid?: boolean
  inputRef?: React.Ref<HTMLInputElement>
  value?: string
}

const getLabel = (
  label: React.ReactNode | string,
  labelPositionStyles: EmotionStyles,
  labelStyles?: EmotionStyles
) => {
  return typeof label === 'string' ? (
    <div
      css={[baseLabelStyles, labelPositionStyles, labelStyles]}
      dangerouslySetInnerHTML={{ __html: label }}
    />
  ) : (
    <div css={[baseLabelStyles, labelPositionStyles, labelStyles]}>{label}</div>
  )
}

export const Checkbox = ({
  checked,
  label,
  labelPosition = 'right',
  containerStyles,
  labelStyles,
  fluid,
  inputRef,
  ...rest
}: Props) => {
  return (
    <label css={[baseContainerStyles, fluid ? fluidContainerStyles : null, containerStyles]}>
      {label && labelPosition === 'left' ? getLabel(label, labelLeftStyles, labelStyles) : null}

      <div
        css={css`
          height: 18px;
        `}
      >
        <input
          checked={checked}
          type="checkbox"
          css={hiddenCheckboxStyles}
          ref={inputRef}
          {...rest}
        />
        <StyledCheckboxIcon />
      </div>

      {label && labelPosition === 'right' ? getLabel(label, labelRightStyles, labelStyles) : null}
    </label>
  )
}

Checkbox.displayName = 'Checkbox'

export default Checkbox
